
/* wall3 detail view */

.wall3-photo {
  width: 100%;
  margin: -2rem 0rem 0rem -2rem;
  padding: 2rem;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;
}
.wall3-photo:hover {
  transform: scale(1.05);
}
.wall3-h2 {
  font-family: 'Helvetica Neue';
  color: #222;
  font-size: 24px;
  font-weight: 400;
  margin: -2rem 0rem 0rem -2rem;
  padding: 2rem;
}
.wall3-h3 {
  font-family: 'Segoe UI';
  font-size: 16px;
}
.wall3-p {
  font-family: 'Segoe UI';
  font-size: 16px;
}