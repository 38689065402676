
/* list view */

.list {
  padding: 0 0 0 0;
  text-align: left;
  min-width: 200px; 
}
.list-grid {
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 2fr;
}