
/* list detail view */

.list-h2 {
  padding: 1rem 0 0 1rem;
  text-align: right;
  font-family: 'Helvetica Neue';
  color: #222;
  font-size: 24px;
  font-weight: 400;
}
.list-figcaption {
  padding: 0 0 0 1rem;
}
.list-h3 {
  font-family: 'Segoe UI';
  font-size: 16px;
}
.list-p {
  margin: -0.5em 0 0 0;
  font-family: 'Segoe UI';
  font-size: 16px;
}