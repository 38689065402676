
.social-icon {
  display: block;
  cursor: pointer;
}
.social-icon-link {
  text-decoration: none;
  color: rgba(0, 0, 0, 1);
}
.social-icon-img {
  max-width: 24px;
  padding: 1.5em 0 0 0;
  margin: 0 0 0 -2.2em;
}