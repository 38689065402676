
nav {
  position: fixed;
  right: 4px;
  z-index: 100;
  text-transform: uppercase;
  font-family: 'Helvetica Neue';
  font-size: 1.4rem;
  font-weight: 800;
  background-color: white;
  align-content: left;
  padding: 0 2em 0 0em;
  box-shadow: 0px 0px 20px rgba(0, 0, 80, .1);
}
nav li {
  display: inline-block;
  cursor: pointer;
}
nav li .link {
  text-decoration: none;
  color: rgba(0, 0, 0, 1);
  margin: .85em;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
li .link:hover {
  color: rgba(100, 100, 80, .6);
}
.active {
  color: rgba(100, 100, 80, .6);
}