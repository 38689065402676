
/* wall2 view */

.wall2 {
  flex-basis: calc(33.333% - 4rem);
  min-width: 20rem; 
  margin: 1rem;
  box-shadow: 0px 0px 20px rgba(0, 0, 80, .1);
  background-color: rgba(255, 255, 255, .6);
  overflow: hidden;
  flex-grow: 1;
}
.wall2-grid {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}